import React, { useState } from "react"
import { Row, Form, Button, Alert } from "react-bootstrap"
import Modal from "react-modal"
import api from "../utils/api"

const modalStyles = {
  content: {
    top: "25%",
    bottom: "auto",
    margin: "auto",
    maxWidth: "300px",
    minWidth: "min-content",
  },
  overlay: {
    zIndex: "5000",
    backgroundColor: "#212529c2",
  },
}

export default function RegistrationModal({ isModalOpen, closeModal, type }) {
  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)

  function close() {
    setError("")
    setLoading(false)
    closeModal()
    setEmail("")
    setName("")
    setLoading(false)
  }

  const register = async () => {
    setLoading(true)
    setError("")
    if (!name) {
      setLoading(false)
      return setError("Name is required")
    }
    if (name.length < 2) {
      setLoading(false)
      return setError("Name has to be more than 2 characters")
    }
    if (!email) {
      setLoading(false)
      return setError("Email is required")
    }
    if (!/\S+@\S+\.\S+/.test(email)) {
      setLoading(false)
      return setError("Email is invalid")
    }

    try {
      await api().post(`/registrations`, { name, email, type })
      setLoading(false)
      close()
    } catch (error) {
      setLoading(false)
      const message = error.response.data.message
      setError(message)
    }
  }

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={close}
      style={modalStyles}
      ariaHideApp={false}
    >
      <Row
        style={{
          backgroundColor: "white",
          color: "black",
          textAlign: "center",
        }}
      >
        <div style={{ margin: "auto" }}>
          <Form
            style={{
              margin: "0px",
              padding: "20px",
              backgroundColor: "#03989e",
            }}
          >
            {error ? <Alert variant="danger">{error}</Alert> : ""}
            <Form.Group className="mb-3">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                value={name}
                onChange={e => setName(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </Form.Group>

            <Button disabled={loading} onClick={register}>
              Submit
            </Button>

            <p>We will get in touch as soon as possible</p>
          </Form>
        </div>
      </Row>
    </Modal>
  )
}
