import React from "react"
import styled from "styled-components"
import { useState } from "react"
import "./styles/CmeTab.css"
import { Card } from "react-bootstrap"
import { Image } from "react-bootstrap"
import { FaArrowDown } from "react-icons/fa"


const Tabs = ({ data }) => {
  const [toggleState, setToggleState] = useState(1)

  const toggleTab = index => {
    setToggleState(index)
  }

  const webinars = data.filter(item => item.type === "webinar")
  const conferences = data.filter(item => item.type === "conference")

  return (
    <div className="container">
      <div className="bloc-tabs">
        <button
          className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
          onClick={() => toggleTab(1)}
        >
          Webinars {toggleState !== 1 && <FaArrowDown />}
        </button>
        <button
          className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
          onClick={() => toggleTab(2)}
        >
          Conferences {toggleState !== 2 && <FaArrowDown />}
        </button>
        <button
          className={toggleState === 3 ? "tabs active-tabs" : "tabs"}
          onClick={() => toggleTab(3)}
        >
          Online Courses {toggleState !== 3 && <FaArrowDown />}
        </button>
        <button
          className={toggleState === 4 ? "tabs active-tabs" : "tabs"}
          onClick={() => toggleTab(4)}
        >
          Others {toggleState !== 4 && <FaArrowDown />}
        </button>
      </div>

      <div className="content-tabs">
        <div
          className={toggleState === 1 ? "content  active-content" : "content"}
        >
          <h2>Webinars</h2>
          {webinars.length == 0 && <h3>No webinars found</h3>}
          <CardsA>
            {webinars.map(webinar => (
              <Cards key={webinar.id}>
                <Image
                  src={webinar.img}
                  fluid
                  alt=""
                  style={{
                    maxWidth: "400px",
                    width: "auto",
                    maxHeight: "300px",
                  }}
                />
                <CardItem>
                  <h5>{webinar.title}</h5>
                  <div
                    css={`
                      border-top: 1px solid #fff;
                    `}
                  >
                    <span
                      css={`
                        color: #000;
                      `}
                    >
                      PROVIDER:{" "}
                    </span>{" "}
                    {webinar.provider}
                  </div>
                  <div>
                    <span
                      css={`
                        color: #000;
                      `}
                    >
                      DATE AND TIME:{" "}
                    </span>
                    {new Date(webinar.date).toLocaleString("en-GB", {
                      timeZone: "Africa/Nairobi",
                    })}
                  </div>
                  <div>
                    <span
                      css={`
                        color: #000;
                      `}
                    >
                      LOCATION:{" "}
                    </span>
                    {webinar.location}
                  </div>
                  <div>
                    <span
                      css={`
                        color: #000;
                      `}
                    >
                      SPEAKER:{" "}
                    </span>
                    {webinar.speaker}
                  </div>
                  <a href={webinar.link} target="_blank" rel="noreferrer" className="btn btn-primary">
                    Register
                  </a>
                </CardItem>
              </Cards>
            ))}
          </CardsA>
        </div>

        <div
          className={toggleState === 2 ? "content  active-content" : "content"}
        >
          <h2>Conferences</h2>
          {conferences.length == 0 && <h3>No conferences found</h3>}
          <CardsA>
            {conferences.map(conference => (
              <Cards key={conference.id}>
                <Image
                  src={conference.img}
                  fluid
                  alt=""
                  style={{
                    maxWidth: "400px",
                    width: "auto",
                    maxHeight: "300px",
                  }}
                />

                <CardItem>
                  <h5>{conference.title}</h5>
                  <div
                    css={`
                      border-top: 1px solid #fff;
                    `}
                  >
                    <span
                      css={`
                        color: #000;
                      `}
                    >
                      PROVIDER:
                    </span>
                    {conference.provider}
                  </div>
                  <div>
                    <span
                      css={`
                        color: #000;
                      `}
                    >
                      DATE AND TIME:{" "}
                    </span>
                    {new Date(conference.date).toLocaleString("en-GB", {
                      timeZone: "Africa/Nairobi",
                    })}
                  </div>
                  <div>
                    <span
                      css={`
                        color: #000;
                      `}
                    >
                      LOCATION:{" "}
                    </span>
                    {conference.location}
                  </div>
                  <div>
                    <span
                      css={`
                        color: #000;
                      `}
                    >
                      SPEAKER:{" "}
                    </span>
                    {conference.speaker}
                  </div>
                  <a href={conference.link} target="_blank" rel="noreferrer" className="btn btn-primary">
                    Register
                  </a>
                </CardItem>
              </Cards>
            ))}
          </CardsA>
        </div>

        <div
          className={toggleState === 3 ? "content  active-content" : "content"}
        >
          <h2>Online Courses</h2>
          <CardsA>
            <Cards>
              <div className="card-body">
                <h5 className="card-title">Coming soon</h5>
                <p
                  className="card-text"
                  css={`
                    border-top: 1px solid #fff;
                  `}
                >
                  Curated List of Online courses from various industry leaders
                </p>

              </div>
            </Cards>
          </CardsA>
        </div>
        <div
          className={toggleState === 4 ? "content  active-content" : "content"}
        >
          <h2>Others</h2>
          <CardsA>
            <Cards>
              <div className="card-body">
                <h5 className="card-title">Coming soon</h5>
                <p
                  className="card-text"
                  css={`
                    border-top: 1px solid #fff;
                  `}
                >
                  Curated List of educational materials from various industry
                  leaders
                </p>

              </div>
            </Cards>
          </CardsA>

        </div>
      </div>
    </div>
  )
}

export default Tabs

const Cards = styled(Card)`
  min-width: 200px;
  width: 90%;
  background: #03989e;
  color: #fff;
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 800px) {
    flex-direction: row;
  }
`
const CardsA = styled.div`
  display: flex;
  flex-direction: column;
`
const CardItem = styled.div`
  width: 100%;
  padding: 1rem 1rem;
`
