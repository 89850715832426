import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import styled from "styled-components"
import pic44 from "../images/canva/44.jpeg"
import RegistrationModal from "../components/RegistrationModal"
import { Image, Button } from "react-bootstrap"
import Tabs from "../components/CmeTab"
import { navigate } from "gatsby"
import { AiOutlineCaretRight } from "react-icons/ai"

const algoliasearch = require("algoliasearch")

const client = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_ADMIN_KEY
)

const index = client.initIndex(
  `${process.env.NODE_ENV == "development" ? "dev_CME" : "prod_CME"}`
)

const Cme = () => {
  const [data, setData] = useState([])
  const [timing, setTiming] = useState("")
  const [isModalOpen, setModalStatus] = useState(false)

  const fetchPast = () => {
    const today = new Date()
    today.setHours(0, 0, 0, 0)

    setTiming("past")

    index
      .search("", {
        page: 0,
        filters: `unixDate < ${today.getTime() / 1000}`,
      })
      .then(({ hits, nbPages, nbHits }) => {
        setData(hits)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const fetchToday = () => {
    const today = new Date()
    today.setHours(0, 0, 0, 0)

    const tomorrow = new Date(today)
    tomorrow.setDate(tomorrow.getDate() + 1)
    setTiming("today")

    index
      .search("", {
        page: 0,
        filters: `unixDate > ${today.getTime() / 1000} AND unixDate < ${tomorrow.getTime() / 1000
          }`,
      })
      .then(({ hits, nbPages, nbHits }) => {
        setData(hits)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const fetchUpcoming = () => {
    const today = new Date()
    today.setHours(0, 0, 0, 0)

    const tomorrow = new Date(today)
    tomorrow.setDate(tomorrow.getDate() + 1)

    setTiming("upcoming")

    index
      .search("", {
        page: 0,
        filters: `unixDate > ${tomorrow.getTime() / 1000}`,
      })
      .then(({ hits, nbPages, nbHits }) => {
        setData(hits)
      })
      .catch(err => {
        console.log(err)
      })
  }

  useEffect(() => {
    fetchToday()
  }, [])

  function openModal() {
    setModalStatus(true)
  }

  function closeModal() {
    setModalStatus(false)
  }

  return (
    <Layout>
      <RegistrationModal
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        type="cme"
      />
      <Container>
        <Seo
          title="Learning at your Convenience with CPD accredited CMEs"
          description="Access CPD accredited continues medical education at your convenience. Find Webinars, conferences, online courses for healthcare providers"
          keywords="cme,cpd points,cpd points kenya,pharmacy and poisons board cpd points,cpd,ppb cpd points,online cpd points for doctor,kma webinars,psk webinars,ppb cpd portal"
          img="https://www.patameds.com/static/44-16ba0dfd49282b66df55963f102b9e6c.jpeg"
          siteUrl="https://www.patameds.com/cme"
          canonical="https://www.patameds.com/cme"
        ></Seo>
        <Hero>
          <Cta>
            <h1>Learning at your Convenience</h1>
            <p>
              Access CPD accredited continues medical education at your
              convenience
            </p>
          </Cta>
          <ImageWrapper>
            <Image
              src={pic44}
              alt="continues medical education"
              fluid
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                maxWidth: "600px",
                width: "100%",
              }}
            />
          </ImageWrapper>
        </Hero>
        <div style={{ padding: "20px 0", width: "70%", margin: "auto" }}>
          <Button
            onClick={() => {
              navigate("/login")
            }}
            style={{
              backgroundColor: "#00A14B", height: "60px"
            }}
          >
            Go To Products Marketplace <AiOutlineCaretRight style={{ display: "inline" }} />
          </Button>
        </div>
        <Content>
          <BtnGroup>
            <div
              onClick={fetchPast}
              style={{
                cursor: "pointer",
                border: `${timing == "past" ? "3px solid white" : ""}`,
              }}
            >
              Past
            </div>
            <div
              onClick={fetchToday}
              style={{
                cursor: "pointer",
                border: `${timing == "today" ? "3px solid white" : ""}`,
              }}
            >
              Today
            </div>
            <div
              onClick={fetchUpcoming}
              style={{
                cursor: "pointer",
                border: `${timing == "upcoming" ? "3px solid white" : ""}`,
              }}
            >
              Upcoming
            </div>
          </BtnGroup>
          <Tabs data={data} />
        </Content>
        <div style={{ padding: "20px 0", width: "70%", margin: "auto" }}>
          <Button
            onClick={openModal}
            style={{
              backgroundColor: "#00A14B", height: "60px"
            }}
          >
            Share your CMEs with us <AiOutlineCaretRight style={{ display: "inline" }} />
          </Button>
        </div>
      </Container>
    </Layout >
  )
}

export default Cme

const Container = styled.div`
  width: 100%;
  height: 100%;
`
const Hero = styled.div`
  width: 100%;
  height: 80vh;
  min-height:100%;
  background: #fff;
  margin-bottom: 3px;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: space-around;
  //margin-top: 2rem;

  @media screen and (min-width: 700px) {
    flex-direction: row;
  }
`
const Cta = styled.div`
  margin-left: 1rem;
  padding: 0rem 3rem;
  flex: 1 1 30em;
`
const ImageWrapper = styled.div`
  flex: 1 1 30em;
`
const Content = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: 1rem;
`
const BtnGroup = styled.div`
  width: 90%;
  padding-top: 1rem;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;

  div {
    width: 100px;
    height: 50px;
    margin-right: 1rem;
    background: #03989e;
    color: #fff;
    border-radius: 1rem;
    text-align: center;
    padding-top: 10px;
  }
`
